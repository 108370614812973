import {
  genericCRUDService,
  get,
  post,
  put,
  remove as removeApiMethod,
} from '../helpers/api';

export const BACKEND_SERVICE_URI = '/portal';
export const ORDERS_BASE_URI = '/api/v1/orders';

// eslint-disable-next-line
export const { findAll, findOne, create, remove } = genericCRUDService(
  BACKEND_SERVICE_URI,
  ORDERS_BASE_URI,
);

export const update = (entity) =>
  put(
    `${BACKEND_SERVICE_URI}${ORDERS_BASE_URI}/${entity.id}${
      entity.action ? `/${entity.action}` : ''
    }`,
    entity,
  );

export const removeCartItem = (accountId, productId) =>
  removeApiMethod(
    `${BACKEND_SERVICE_URI}${ORDERS_BASE_URI}/accounts/${accountId}/product/${productId}`,
  ).then((res) => res.json());

export const getActiveOrders = () =>
  get(`${BACKEND_SERVICE_URI}${ORDERS_BASE_URI}/current`);

export const addProduct = (accountId, product) =>
  post(
    `${BACKEND_SERVICE_URI}${ORDERS_BASE_URI}/accounts/${accountId}`,
    product,
  );

export const sendOPNotification = (id) =>
  put(`${BACKEND_SERVICE_URI}${ORDERS_BASE_URI}/sendOPNotification/${id}`);

export const rechargeProduct = (accountId, product) =>
  post(
    `${BACKEND_SERVICE_URI}${ORDERS_BASE_URI}/accounts/${accountId}/recharge`,
    product,
  );

export const verifyProduct = (productId, quantity) =>
  get(
    `${BACKEND_SERVICE_URI}${ORDERS_BASE_URI}/verify-product-rechargeability/${productId}?checkQuantity=${quantity}`,
  );

export const addInvoiceType = (invoiceType) =>
  put(`${BACKEND_SERVICE_URI}${ORDERS_BASE_URI}/addInvoiceType`, invoiceType);

export const addLegalFieldsWithOrderId = (data, id) =>
  put(`${BACKEND_SERVICE_URI}${ORDERS_BASE_URI}/addLegalFields/${id}`, data);

export const addStocksLegalFields = (data, id) =>
  put(
    `${BACKEND_SERVICE_URI}${ORDERS_BASE_URI}/invoice-pj/addLegalFields/${id}`,
    data,
  );

export const generateRaportGratuitati = (gratuitatiReportData) =>
  post(
    `${BACKEND_SERVICE_URI}${ORDERS_BASE_URI}/reports/gratuitati/generate`,
    gratuitatiReportData,
  );

export const generateRaportGratuitatiPreview = (
  gratuitatiReportData,
  reportName,
) =>
  fetch(
    `${window._env_.API_BACKEND_URL}${BACKEND_SERVICE_URI}${ORDERS_BASE_URI}/reports/gratuitati/preview`,
    {
      method: 'POST',
      credentials: 'include',
      headers: {
        'X-Tenant-Key': window._env_.API_TENANT_KEY,
        'Content-Type': 'application/json',
        Accept: 'application/octet-stream',
      },
      body: JSON.stringify(gratuitatiReportData),
    },
  )
    .then((res) =>
      res.blob().then((val) => {
        return res.ok ? Promise.resolve(val) : Promise.reject(val);
      }),
    )
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.target = '_blank';
      a.download = reportName;
      document.body.appendChild(a);
      a.click();
      a.remove();
    });
