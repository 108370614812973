import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Button, notification, Space, Table, Tooltip } from 'antd';
import { FileTextOutlined } from '@ant-design/icons';
import Column from '../../helpers/columns';
import { getDocTypes, getUserTypes } from '../../services/taxonomies';
import useDictionaries from '../../hooks/useDictionaries';
import { FormContext } from '../../hooks/useForm';
import DeleteItemIcon from '../buttons/DeleteItemIcon';
import FormWrapper from '../FormWrapper';
import {
  downloadFiles,
  SERVICE_URI as FILE_SERVICE_URI,
  BASE_URI as FILE_BASE_URI,
} from '../../services/files';
import { getProducts } from '../../services/products';
import SearchSubdivisions from './SearchSubdivisions';

const dictionaries = {
  docTypesTaxonomy: getDocTypes,
  userTypes: getUserTypes,
  productTypes: getProducts,
};

const ProductUsersList = ({ index, disabled, fields, institutionId }) => {
  const { t } = useTranslation();
  const form = useContext(FormContext);
  const [documentTypes, setDocTypes] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const [{ docTypesTaxonomy, userTypes, productTypes }] =
    useDictionaries(dictionaries);

  const { input, value, setValue, custom, checkbox } = form;

  const { products = [], id: orderId } = value;
  const { persons = [], productId } = products[index];

  const onRemove = useCallback(
    async (record) => {
      const newProducts = products.map((product) => ({
        ...product,
        persons: product.persons.filter((pr) =>
          record.key ? pr.key !== record.key : pr.id !== record.id,
        ),
      }));
      setValue('products', newProducts);
    },
    [setValue, products],
  );

  const onUploadFailed = useCallback(
    (msg) => {
      const messageCode = msg?.inner?._;
      notification.error({
        message: messageCode
          ? t(messageCode)
          : 'A aparut o eroare, incercati din nou',
      });
    },
    [t],
  );

  useEffect(() => {
    const { userTypeId } =
      // eslint-disable-next-line eqeqeq
      productTypes.content.find((pt) => pt.id == productId) || {};
    // eslint-disable-next-line eqeqeq
    const type = userTypes.content.find((c) => c.id == userTypeId);
    const docs = type?.docTypes || [];

    const res = docTypesTaxonomy.content.filter((dt) => docs.includes(dt.id));
    setDocTypes(res.sort((a, b) => a.id - b.id));
  }, [docTypesTaxonomy.content, productId, productTypes, userTypes.content]);

  const getDocColumns = useCallback(() => {
    return documentTypes.map((dt, docIndex) =>
      Column.actions(dt.name, (record, rowIndex) => {
        const currentDoc = record?.documents?.find(
          (savedDoc) => savedDoc && savedDoc.documentTypeId === dt.id,
        );
        return (
          <>
            <FormWrapper.FileUploader
              props={{
                disabled,
                multiple: false,
                uploadUrl: `${FILE_SERVICE_URI}${FILE_BASE_URI}/${orderId}/files`,
                ...custom(
                  `products[${index}].persons[${rowIndex}].documents[${docIndex}]`,
                ),
                setValue: (name, uploadedFiles) => {
                  setValue(name, {
                    fileId: uploadedFiles[0].id,
                    documentTypeId: dt.id,
                  });
                },
                onUploadFailed,
              }}
            />

            {currentDoc && (
              <Space style={{ paddingLeft: '1rem' }}>
                <Tooltip title={t('actions.orders.viewDoc')}>
                  <Button
                    shape="circle"
                    type="link"
                    icon={<FileTextOutlined />}
                    style={{ color: '#24ad09' }}
                    onClick={() => downloadFiles(currentDoc.fileId, orderId)}
                  />
                </Tooltip>
              </Space>
            )}
          </>
        );
      }),
    );
  }, [
    custom,
    disabled,
    documentTypes,
    index,
    orderId,
    setValue,
    t,
    onUploadFailed,
  ]);

  const columns = useMemo(
    () => [
      ...fields
        .filter((it) => it !== 'subdivisionId')
        .map((name) =>
          name !== 'foreignCitizen'
            ? {
                title: t(`entity.admin.user.${name}`),
                dataIndex: name,
                key: name,
                sorter: true,
                width: 150,
                render: (_, record, idx) => (
                  <FormWrapper.Input
                    props={{
                      ...input(`products[${index}].persons[${idx}].${name}`),
                      disabled,
                      ...(name === 'fullname'
                        ? {
                            onChange: (e) => {
                              const cursorPosition = e.target.selectionStart;
                              const newText =
                                e.target.value.substring(
                                  0,
                                  cursorPosition - 1,
                                ) +
                                (cursorPosition > 0
                                  ? e.target.value[
                                      cursorPosition - 1
                                    ].toUpperCase()
                                  : '') +
                                e.target.value.substring(cursorPosition);

                              setValue(
                                `products[${index}].persons[${idx}].fullname`,
                                newText.toUpperCase(),
                              );

                              requestAnimationFrame(() => {
                                e.target.setSelectionRange(
                                  cursorPosition,
                                  cursorPosition,
                                );
                              });
                            },
                          }
                        : {}),
                    }}
                  />
                ),
              }
            : {
                title: t('entity.admin.user.foreignCitizen'),
                dataIndex: 'foreignCitizen',
                key: 'foreignCitizen',
                sorter: true,
                width: 150,
                render: (_, record, idx) => (
                  <FormWrapper.Switch
                    props={{
                      disabled,
                      searchField: 'name',
                      field: 'id',
                      institutionId,
                      ...checkbox(
                        `products[${index}].persons[${idx}].foreignCitizen`,
                      ),
                    }}
                  />
                ),
              },
        ),
      ...(fields.includes('subdivisionId')
        ? [
            {
              title: t('entity.admin.user.subdivisionId'),
              dataIndex: 'subdivisionId',
              key: 'subdivisionId',
              sorter: true,
              width: 150,
              render: (_, record, idx) => (
                <SearchSubdivisions
                  props={{
                    disabled,
                    searchField: 'name',
                    field: 'id',
                    institutionId,
                    ...custom(
                      `products[${index}].persons[${idx}].subdivisionId`,
                    ),
                  }}
                />
              ),
            },
          ]
        : []),
      ...getDocColumns(),
      Column.actions(t('table.actions'), (record) => (
        <DeleteItemIcon
          title={t('entity.admin.user._delete', record)}
          message={t('entity.admin.user._deleted', record)}
          item={record}
          reload={() => null}
          action={onRemove}
          disabled={disabled}
        />
      )),
    ],
    [
      disabled,
      fields,
      getDocColumns,
      index,
      input,
      onRemove,
      t,
      custom,
      institutionId,
      checkbox,
      setValue,
    ],
  );

  return (
    <Table
      columns={columns}
      rowKey={(record) => record.key}
      pagination={false}
      dataSource={persons}
    />
  );
};

export default ProductUsersList;
