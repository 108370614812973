import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Space,
  Tooltip,
  Upload,
} from 'antd';
import moment from 'moment';
import dayjs from 'dayjs';
import i18n from 'i18next';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { range } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import FormWrapper from '../FormWrapper';
import useDictionaries from '../../hooks/useDictionaries';
import './OrderFileList.scss';
import { getProducts } from '../../services/products';

import { FormContext } from '../../hooks/useForm';
import ProductUsersList from './ProductUsersList';
import { csvToJson } from '../../helpers/csv';
import POSButton from './POSButton';
import { BULK_TYPES, ORDER_STATUS } from '../../pages/orders/constants';
import { getUsersWithExistingCards } from '../../services/users';
import Restriction from '../Restriction';
import { getSubdivisions, getUserTypes } from '../../services/taxonomies';
import DatePickerLocale from '../DatePickerLocale';
import { PosTypesEnum } from '../../services/constants';

const dictionaries = {
  productTypes: getProducts,
  userTypes: getUserTypes,
};

const Products = ({ status }) => {
  const form = useContext(FormContext);
  const [configForm] = Form.useForm();
  const { t } = useTranslation();
  const { language } = i18n;

  const { select, value, setValue } = form;
  const { products = [], disabled, bulkType } = value;
  const notStorableProducts = products.filter(
    (product) => product.productCode !== 'MIFARE_1K',
  );
  if (!products[0]) {
    products.push({ persons: [], key: uuidv4() });
  }
  dayjs.extend(customParseFormat);
  const [{ productTypes, userTypes }] = useDictionaries(dictionaries);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [currentProduct, setCurrentProduct] = useState(null);
  const [cardProduct, setCardProduct] = useState(null);

  let requiredFields;
  let omittedFields;

  switch (bulkType) {
    case BULK_TYPES.ISSUING_CARDS:
      requiredFields = ['fullname', 'identityCard'];
      omittedFields = [
        'email',
        'fullname',
        'identityCard',
        'employeeNumber',
        'metaData1',
        'metaData2',
        'phone',
        'subdivisionId',
      ];
      break;
    case BULK_TYPES.RECHARGE_CARDS:
      requiredFields = ['cardLogicalId'];
      omittedFields = [
        'email',
        'identityCard',
        'cardLogicalId',
        'fullname',
        'metaData1',
        'metaData2',
        'subdivisionId',
      ];
      break;
    default:
      requiredFields = null;
  }

  useEffect(() => {
    getProducts({
      criterias: {
        code: 'MIFARE_1K',
        dateEnd: moment().toISOString(),
      },
    }).then((data) => {
      setCardProduct(data.content[0]);
    });
  }, []);

  const configureProduct = useCallback(
    (product, idx) => {
      const originalProduct = productTypes?.content.find(
        ({ id }) => id === product?.productId,
      );
      configForm.setFieldsValue({
        product: {
          ...product,
          count: product.count || 1,
          startDate: product.startDate && moment(product.startDate),
        },
        idx,
        originalProduct,
      });
      setIsModalVisible(true);
    },
    [configForm, productTypes],
  );
  useCallback(
    async (record) => {
      const updatedProducts = products.map((product) =>
        product.key === record.key ? { key: uuidv4, persons: [] } : product,
      );
      setValue('products', updatedProducts);
    },
    [products, setValue],
  );
  // eslint-disable-next-line arrow-body-style
  const disabledDate = (current) => {
    // Can not select days before today and today
    return (
      current < moment().add(-1, 'day') ||
      Date.parse(current) > Date.parse(moment().add(90, 'days'))
    );
  };
  const disabledTime = (current) => {
    if (moment().isSame(current, 'day')) {
      // Disable hours before the current hour
      const currentHour = moment().hour();
      const currentMinute = moment().minute();
      const currentSecond = moment().second();

      return {
        disabledHours: () => range(0, currentHour),
        disabledMinutes: (hour) =>
          hour === currentHour ? range(0, currentMinute) : [],
        disabledSeconds: (hour, minute) =>
          hour === currentHour && minute === currentMinute
            ? range(0, currentSecond)
            : [],
      };
    }

    return null; // Enable all hours for other days
  };

  const getSubdivisionsByInstitutionId = useCallback((institutionId) => {
    if (institutionId) {
      return getSubdivisions({
        pageSize: 1000,
        criterias: { institutionId },
      }).then((res) => {
        return Promise.resolve(res.content);
      });
    }
    return [];
  }, []);

  const getUserTypeId = useCallback(
    (productId) => {
      return productTypes?.content?.find((pt) => pt.id === productId)
        ?.userTypeId;
    },
    [productTypes.content],
  );

  const getProduct = useCallback(
    (productId) => {
      return productTypes?.content?.find((pt) => pt.id === productId);
    },
    [productTypes.content],
  );

  const getCSVData = useCallback(
    async (idx, file, expectedFields, excludedFields) => {
      const createErrorMessage = (validationResults) => {
        const { missingFields, extraFields } = validationResults;
        const missingFieldsMsg =
          missingFields.length > 0 && missingFields.every(Boolean)
            ? `${t('errors.missingFields')}: ${missingFields.join(', ')}`
            : '';
        const extraFieldsMsg =
          extraFields.length > 0 && extraFields.every(Boolean)
            ? `${missingFieldsMsg ? `${t('conjunctions.and')} ` : ''}${
                missingFieldsMsg
                  ? t('errors.extraFields').toLowerCase()
                  : t('errors.extraFields')
              }: ${extraFields.join(', ')}`
            : '';

        return `${missingFieldsMsg} ${extraFieldsMsg}`;
      };

      const validateCSVFields = (csvData) => {
        const actualFields = csvData.length > 0 ? Object.keys(csvData[0]) : [];
        const missingFields = expectedFields.filter(
          (field) => !actualFields.includes(field),
        );

        const extraFields = actualFields.filter((field) => {
          return !excludedFields.includes(field);
        });

        return { missingFields, extraFields };
      };

      // Simple verification that the file is a CSV
      if (file.type !== 'text/csv') {
        notification.error({
          message: t('errors.invalidFileType'),
        });
        return Promise.resolve(null);
      }

      const { persons = [], userTypeId, institutionId } = products[idx];
      const subdivisions = await getSubdivisionsByInstitutionId(institutionId);

      const reader = new FileReader();
      reader.onload = (e) => {
        const csvData = csvToJson(e.target.result);

        csvData.forEach((pers, index, array) => {
          const matchingSubdivision = subdivisions.find(
            (s) => s.name === pers.subdivisionId,
          );
          if (matchingSubdivision) {
            // eslint-disable-next-line no-param-reassign
            array[index] = { ...pers, subdivisionId: matchingSubdivision.id };
          }
        });

        const validationResults = validateCSVFields(csvData);

        if (!validationResults) {
          notification.error({
            message: t('errors.emptyFile'),
          });
          return;
        }

        const { missingFields, extraFields } = validationResults;
        if (missingFields.length > 0 || extraFields.length > 0) {
          notification.error({
            message: t('errors.csv'),
            description: createErrorMessage(validationResults),
          });
          return;
        }

        const mailList = csvToJson(e.target.result).map((pers) => pers.email);
        getUsersWithExistingCards(mailList).then((emails) => {
          return emails.length > 0 &&
            emails.filter((mail) => mail !== null && mail !== '').length > 0
            ? notification.warning({
                message: `Exista deja carduri pentru urmatoarele adrese mail : ${emails.join(
                  '\n',
                )}`,
              })
            : '';
        });

        setValue(`products[${idx}].persons`, [
          ...persons,
          ...csvToJson(e.target.result).map((p) => ({
            ...p,
            email: p.email === '' ? null : p.email,
            userTypeId:
              userTypeId != null ? userTypeId : getUserTypeId(selectedProduct),
            key: uuidv4(),
          })),
        ]);
      };

      reader.readAsText(file);

      return Upload.LIST_IGNORE;
    },
    [
      products,
      getSubdivisionsByInstitutionId,
      t,
      setValue,
      getUserTypeId,
      selectedProduct,
    ],
  );

  const addUser = useCallback(
    (index) => {
      const { persons = [] } = products[index];
      const userTypeId = getUserTypeId(products[index].productId);
      setValue(`products[${index}].persons`, [
        ...persons,
        {
          key: uuidv4(),
          userTypeId,
        },
      ]);
    },
    [setValue, products, getUserTypeId],
  );

  return (
    <>
      {/* <PageTitle title={t('entity.orders.productList._plural')}>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          disabled={disabled}
          onClick={() => {
            setValue('products', [...products, { key: uuidv4() }]);
          }}
        >
          {t('entity.orders.add')}
        </Button>
      </PageTitle> */}
      {notStorableProducts.map((product, idx) => (
        <div key={product.key || product.id}>
          <FormWrapper>
            <FormWrapper.Single>
              <FormWrapper.SelectProduct
                label={t('entity.orders.product')}
                props={{
                  disabled,
                  lang: language,
                  productName: products[idx]?.productName,
                  options: productTypes.content
                    .filter((prd) =>
                      prd.posTypes?.includes(PosTypesEnum.PORTAL_PJ),
                    )
                    .map((type) => ({
                      ...type,
                      disabled: products.find((p) => p.productId === type.id),
                    })),
                  ...select(`products[${idx}].productId`),
                  onChange: (productId) => {
                    setSelectedProduct(productId);
                    const { name, code, userTypeId } = getProduct(productId);
                    productTypes.content
                      .filter((pt) => pt.id === productId)
                      .map((productType) =>
                        setCurrentProduct(productType.localizedName[language]),
                      );
                    const persons = value?.products[idx]?.persons?.map(
                      (pers) => ({
                        ...pers,
                        subdivisionId: null,
                        userTypeId,
                      }),
                    );
                    setValue(`products[${idx}]`, {
                      ...products[idx],
                      productId,
                      userTypeId,
                      persons,
                      productCode: code,
                      productName: name,
                      startDate: null,
                      institutionId: null,
                    });
                    const ids = userTypes.content.find(
                      (ut) => getUserTypeId(productId) === ut.id,
                    )?.institutions;
                    setValue(`products[${idx}].institutionId`, ids[0]);
                  },
                }}
              />
            </FormWrapper.Single>
            {bulkType === BULK_TYPES.ISSUING_CARDS ? (
              <FormWrapper.Single>
                {cardProduct ? (
                  <FormWrapper.Input
                    label={t('entity.orders.card')}
                    props={{
                      disabled: true,
                      value: cardProduct.name,
                    }}
                  />
                ) : null}
              </FormWrapper.Single>
            ) : null}
            {bulkType === BULK_TYPES.ISSUING_CARDS && (
              <FormWrapper.Single>
                <Form.Item hidden name={`products[${idx}].institutionId`} />
              </FormWrapper.Single>
            )}
            {[ORDER_STATUS.SELECT_PRODUCTS].includes(status) && (
              <Row style={{ padding: '1rem 0' }} justify="start">
                <Col style={{ verticalAlign: 'top', align: 'center' }}>
                  <Tooltip title={t('entity.orders.configureProduct')}>
                    <Button
                      disabled={!products[idx].productId}
                      shape="square"
                      type="link"
                      style={{
                        color: '#fafafa',
                        background: '#1890ff',
                        marginRight: 10,
                      }}
                      // icon={<SettingOutlined style={{ fontSize: '150%' }} />}
                      onClick={() => configureProduct(product, idx)}
                    >
                      {t('entity.orders.configureProduct')}
                    </Button>
                  </Tooltip>
                </Col>
                <Col>
                  <Tooltip title={t('entity.orders.uploadPersons')}>
                    <Upload
                      size="small"
                      dragger={false}
                      beforeUpload={(file) =>
                        getCSVData(idx, file, requiredFields, omittedFields)
                      }
                      multiple={false}
                      accept=".csv"
                      // disabled={disabled || !products[idx].productId}
                    >
                      <Button
                        shape="square"
                        disabled={!products[idx].productId}
                        // icon={
                        //   <UploadOutlined
                        //     style={{ fontSize: '150%', color: '#f6f6f6' }}
                        //   />
                        // }
                        style={{
                          color: '#fafafa',
                          background: '#1890ff',
                          marginRight: 10,
                        }}
                      >
                        {t('entity.orders.uploadPersons')}
                      </Button>
                    </Upload>
                  </Tooltip>
                </Col>
                <Col>
                  <Tooltip title={t('entity.orders.addPerson')}>
                    <Button
                      disabled={!products[idx].productId}
                      shape="square"
                      style={{
                        color: '#fafafa',
                        background: '#1890ff',
                        marginRight: 10,
                      }}
                      type="link"
                      // icon={<UsergroupAddOutlined style={{ fontSize: '150%' }} />}
                      onClick={() => addUser(idx)}
                    >
                      {t('entity.orders.addPerson')}
                    </Button>
                  </Tooltip>
                </Col>
              </Row>
            )}
            <h3
              style={{
                marginRight: '10%',
                marginLeft: 'auto',
              }}
            >
              {products[idx]?.persons?.length || 0}{' '}
              {products[idx]?.persons?.length === 1
                ? t('entity.orders.record')
                : t('entity.orders.records')}
            </h3>
          </FormWrapper>
          <Row>
            <Col span={24}>
              {product.persons && product.persons.length > 0 && (
                <ProductUsersList
                  index={idx}
                  disabled={disabled}
                  institutionId={products[idx].institutionId}
                  fields={
                    bulkType === BULK_TYPES.ISSUING_CARDS
                      ? [
                          'email',
                          'fullname',
                          'foreignCitizen',
                          'identityCard',
                          'phone',
                          // 'metaData1',
                          // 'subdivisionId',
                        ]
                      : ['cardLogicalId', 'fullname', 'identityCard']
                  }
                />
              )}
            </Col>
          </Row>
          <Space direction="vertical">
            <Divider dashed />
          </Space>
        </div>
      ))}

      <Modal
        title={t(`entity.orders.configuration`)}
        visible={isModalVisible}
        onOk={() => {
          configForm
            .validateFields()
            .then((val) => {
              const idx = configForm.getFieldValue('idx');
              const { product } = val;

              setValue(`products[${idx}]`, {
                ...products[idx],
                ...product,
              });
              setIsModalVisible(false);
            })
            .catch((info) => {
              console.error('Validate Failed:', info);
            });
        }}
        onCancel={() => {
          configForm.resetFields();
          setIsModalVisible(false);
        }}
      >
        <Form form={configForm} layout="vertical">
          <Form.Item
            noStyle
            shouldUpdate={(prev, next) =>
              prev.originalProduct?.category !== next.originalProduct?.category
            }
          >
            {({ getFieldValue }) => {
              const categ = getFieldValue(['originalProduct', 'category']);
              return (
                <>
                  <Form.Item style={{ textAlign: 'center' }}>
                    <h3>{currentProduct}</h3>
                  </Form.Item>

                  {categ && (categ === 'TICKET' || categ === 'PURSE') && (
                    <Row gutter={16}>
                      <Col span={8}>
                        <POSButton
                          title="Scade"
                          onClick={() =>
                            configForm.setFieldsValue({
                              product: {
                                ...configForm.getFieldValue('product'),
                                count: Math.max(
                                  1,
                                  configForm.getFieldValue([
                                    'product',
                                    'count',
                                  ]) - 1,
                                ),
                              },
                            })
                          }
                          color="red"
                          size="xsmall"
                        />
                      </Col>
                      <Col span={8}>
                        <Form.Item noStyle name={['product', 'count']}>
                          <Input
                            style={{ height: '100%', fontSize: '2rem' }}
                            disabled
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <POSButton
                          title="Adauga"
                          onClick={() =>
                            configForm.setFieldsValue({
                              product: {
                                ...configForm.getFieldValue('product'),
                                count:
                                  configForm.getFieldValue([
                                    'product',
                                    'count',
                                  ]) + 1,
                              },
                            })
                          }
                          color="green"
                          size="xsmall"
                        />
                      </Col>
                    </Row>
                  )}
                </>
              );
            }}
          </Form.Item>

          <Divider dashed />

          <Form.Item
            noStyle
            shouldUpdate={(prev, next) => {
              return prev?.product?.productId !== next?.product?.productId;
            }}
          >
            {({ getFieldValue }) => {
              return (
                getFieldValue(['originalProduct', 'validityStartType']) ===
                  'FUTURE' && (
                  <Form.Item
                    name={['product', 'startDate']}
                    label={t('entity.orders.startDateSubscription')}
                    rules={[
                      {
                        required: true,
                        message: t('errors.selectDate'),
                      },
                    ]}
                  >
                    <DatePickerLocale
                      showTime
                      shouldNotBeDisabled={false}
                      format="DD.MM.YYYY HH:mm"
                      disabledDate={disabledDate}
                      disabledTime={disabledTime}
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                )
              );
            }}
          </Form.Item>

          <Form.Item
            noStyle
            shouldUpdate={(prev, next) => {
              return prev?.originalProduct?.id !== next?.originalProduct?.id;
            }}
          >
            {({ getFieldValue }) => {
              const restrictions = getFieldValue([
                'originalProduct',
                'productRestrictions',
              ])?.find(({ restriction }) => restriction === 'LINE_GROUPS');
              return (
                restrictions && (
                  <Restriction
                    form={configForm}
                    restrictions={restrictions}
                    line={null}
                    whereIsRestriction={null}
                  />
                )
              );
            }}
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Products;
