import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './styles/products.css';

import {
  Button,
  Card,
  Col,
  Form,
  InputNumber,
  Menu,
  message,
  Modal,
  Row,
  Skeleton,
  Tooltip,
} from 'antd';
import {
  MinusSquareOutlined,
  PlusSquareOutlined,
  ShoppingCartOutlined,
} from '@ant-design/icons';

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import Div from '../Div';

import {
  getOfferTypes,
  getProductsByOfferTypeReq,
  getTitlesByAccountId,
} from '../../services/accounts';
import CartContext from '../CartContextWrapper';
import { addProduct } from '../../services/orders';
import DatePickerLocale from '../DatePickerLocale';
import Restrictions from '../Restrictions';

const Products = ({ id }) => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;

  const { refreshCart } = useContext(CartContext);

  const [currentOfferType, setCurrentOfferType] = useState(undefined);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [offerTypes, setOfferTypes] = useState(undefined);
  const [products, setProducts] = useState([]);

  const [titles, setTitles] = useState([]);
  const [line, setLine] = useState(null);
  const [whereIsRestriction, setWhereIsRestriction] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  dayjs.extend(customParseFormat);

  useEffect(() => {
    getTitlesByAccountId(id).then((res) => {
      setTitles(res || []);
    });
  }, [id]);

  useEffect(() => {
    getOfferTypes().then((result) => {
      setOfferTypes(result || []);
      setCurrentOfferType(result[0]);
      setSelectedKeys([`${result[0].id}${0}`]);
    });
  }, [id]);

  const handleMenuItemClick = (offerType, idx) => {
    setCurrentOfferType(offerType);
    setSelectedKeys([`${offerType.id}${idx}`]);
  };

  useEffect(() => {
    if (currentOfferType !== undefined) {
      setProducts([]);
      const offerTypeReq = {
        products: currentOfferType.products,
        productGroups: currentOfferType.productGroups,
        offers: currentOfferType.offers,
      };
      getProductsByOfferTypeReq(id, offerTypeReq).then((result) => {
        const updatedProducts = (result || []).map((product) => {
          return {
            ...product,
            payload: product.payload.map((item) => ({
              ...item,
              restrictions: product.productRestrictions || [],
            })),
          };
        });
        setProducts(updatedProducts);
      });
    }
  }, [id, offerTypes, currentOfferType]);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isOkButtonDisabled, setIsOkButtonDisabled] = useState(false);
  const [form] = Form.useForm();
  const [maxValue, setMaxValue] = useState(50);

  const manageCount = (operationType) => {
    const { count } = form.getFieldsValue().product;
    if (operationType === 'addition' && (!maxValue || maxValue > count)) {
      form.setFieldsValue({
        product: {
          ...form.getFieldsValue(),
          count: form.getFieldsValue().product.count + 1,
        },
      });
    }
    if (operationType === 'subtraction') {
      form.setFieldsValue({
        product: {
          ...form.getFieldsValue(),
          count:
            form.getFieldsValue().product.count <= 0
              ? 1
              : form.getFieldsValue().product.count - 1,
        },
      });
    }
  };
  const addProductToBasket = useCallback(
    (product) => {
      const hasRestriction = product.productRestrictions?.some(
        (item) =>
          item.restriction === 'LINE_GROUPS' &&
          (item.value1 === 1 ||
            item.value3 === 1 ||
            item.value5 === 1 ||
            item.value7 === 1),
      );
      if (hasRestriction) {
        const whereIsRestrictionData = product.productRestrictions
          .map((item) => {
            if (
              item.restriction === 'LINE_GROUPS' &&
              (item.value1 === 1 ||
                item.value3 === 1 ||
                item.value5 === 1 ||
                item.value7 === 1)
            ) {
              const results = [];
              if (item.value1 === 1) {
                results.push('value1');
              }
              if (item.value3 === 1) {
                results.push('value3');
              }
              if (item.value5 === 1) {
                results.push('value5');
              }
              if (item.value7 === 1) {
                results.push('value7');
              }
              return results;
            }
            return [];
          })
          .filter((list) => list.length > 0);

        const titleFound = titles.find(
          (title) => title.productId === product.id,
        );
        const ownedTitle = titleFound !== undefined ? titleFound : null;
        const now = new Date();
        const isDateAOutsideInterval =
          ownedTitle?.endDateA === null ||
          (ownedTitle?.endDateA != null &&
            new Date(ownedTitle?.endDateA) < now);
        const isDateBOutsideInterval =
          ownedTitle?.endDateB === null ||
          (ownedTitle?.endDateB != null &&
            new Date(ownedTitle?.endDateB) < now);
        const canAddTitleOnEveryLine =
          ownedTitle === null ||
          (isDateBOutsideInterval === true && isDateAOutsideInterval === true);
        if (
          product?.category === 'PASS' &&
          hasRestriction &&
          canAddTitleOnEveryLine === false
        ) {
          setLine(ownedTitle?.lines);
          setWhereIsRestriction(whereIsRestrictionData);
        }
      }
      form.setFieldsValue({
        product: { ...product, count: 1 },
      });

      setSelectedProduct(product);

      if (form.getFieldValue('product').maxValue) {
        setMaxValue(form.getFieldValue('product').maxValue);
      }
      setIsModalVisible(true);
    },
    [form, titles],
  );

  const handleOk = useCallback(
    (value) => {
      const { product } = value;

      if (product.count < 1) {
        message.error({
          content: t('errors.countError'),
          duration: 2,
          className: 'card-message',
        });
        return;
      }

      if (product.category === 'PURSE') {
        if (
          selectedProduct?.maxValue &&
          product.count * 100 > selectedProduct.maxValue
        ) {
          setIsModalVisible(true);
          return;
        }
        product.count *= 100;
      }

      product.localizedName = product.localizedName?.[language];
      setIsOkButtonDisabled(true);
      addProduct(id, product)
        .then(() => {
          setIsModalVisible(false);
          refreshCart();
          message.success({
            content: t('errors.productAddedToCart'),
            key: 'pos',
            duration: 1,
            className: 'card-message',
          });
        })

        .catch((err) => {
          console.error(err);
          message.error({
            content: err?.inner?._
              ? t(err?.inner?._)
              : t('errors.productNotAddedToCart'),
            key: 'pos',
            duration: 1,
            className: 'card-message',
          });
          setIsModalVisible(false);
        })
        .finally(() => {
          setIsOkButtonDisabled(false);
        });
    },
    [language, id, t, selectedProduct, refreshCart],
  );

  return (
    <>
      <Skeleton loading={offerTypes === undefined} active>
        <Row gutter={10}>
          <Col span={4}>
            <Menu mode="inline" selectedKeys={selectedKeys}>
              {offerTypes?.map((offerType, idx) =>
                offerType.name === '-' ? (
                  <Menu.Divider
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${offerType.id}${idx}`}
                  />
                ) : (
                  <Menu.Item
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${offerType.id}${idx}`}
                    onClick={() => handleMenuItemClick(offerType, idx)}
                  >
                    {offerType?.localizedName?.[language]}
                  </Menu.Item>
                ),
              )}
            </Menu>
          </Col>
          <Col span={20}>
            <Row gutter={10}>
              {(products || []).map((product) => {
                return (
                  <Col
                    span={6}
                    key={product?.id}
                    style={{ paddingBottom: '10px' }}
                  >
                    <Card
                      title={
                        <Tooltip
                          title={
                            product?.localizedName?.[language] ?? product?.name
                          }
                        >
                          {product?.localizedName?.[language] ?? product?.name}
                        </Tooltip>
                      }
                      bordered
                      actions={[
                        <Tooltip title={t('actions.purchase')} key="purchase">
                          <ShoppingCartOutlined
                            onClick={() => addProductToBasket(product)}
                          />
                        </Tooltip>,
                      ].filter((v) => !!v)}
                    >
                      {product.category === 'PURSE'
                        ? '-'
                        : // eslint-disable-next-line no-unsafe-optional-chaining
                          product?.price / 100}{' '}
                      RON
                    </Card>
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
      </Skeleton>

      <Modal
        title={t(`entity.orders.configuration`)}
        visible={isModalVisible}
        okButtonProps={{ disabled: isOkButtonDisabled }}
        cancelButtonProps={{ disabled: isOkButtonDisabled }}
        onOk={() => {
          form
            .validateFields()
            .then(handleOk)
            .catch((info) => {
              console.error('Validate Failed:', info);

              const reducerResult = (info.errorFields || [])?.reduce(
                (acc, ve, index) => ({ ...acc, [index]: ve.errors }),
                {},
              );

              if (reducerResult) {
                Object.values(reducerResult).forEach((msg) => {
                  message.error({
                    content: msg,
                    duration: 1,
                    className: 'card-message',
                  });
                });
              }
            });
        }}
        onCancel={() => setIsModalVisible(false)}
      >
        <Form form={form} layout="vertical">
          <Form.Item hidden name={['product', 'id']} />
          <Form.Item hidden name={['product', 'category']} />
          <Form.Item hidden name={['product', `localizedName`]} />
          <Form.Item
            noStyle
            shouldUpdate={(prev, next) =>
              prev.product?.category !== next.product?.category
            }
          >
            {({ getFieldValue }) => {
              const category = getFieldValue(['product', 'category']);
              return (
                category !== 'PASS' && (
                  <Row gutter={16}>
                    <Col span={3}>
                      <Button
                        type="default"
                        shape="circle"
                        icon={<MinusSquareOutlined />}
                        onClick={() => manageCount('subtraction')}

                        // size={size}
                      />
                    </Col>
                    {category !== 'PURSE' && (
                      <Col span={18}>
                        <Form.Item noStyle name={['product', 'count']}>
                          <InputNumber
                            // style={{ height: '100%', fontSize: '2rem' }}
                            min={1}
                            max={maxValue}
                            defaultValue={3}
                          />
                        </Form.Item>
                      </Col>
                    )}
                    {category === 'PURSE' && (
                      <Col span={18}>
                        <Form.Item noStyle name={['product', 'count']}>
                          <InputNumber
                            style={{ width: '100%' }}
                            min={1}
                            max={maxValue ? maxValue / 100 : undefined}
                            defaultValue={1}
                            step={0.01}
                            addonAfter="RON"
                            parser={(purseVal) => purseVal.replace(',', '.')}
                          />
                        </Form.Item>
                      </Col>
                    )}
                    <Col span={3}>
                      <Button
                        type="default"
                        shape="circle"
                        icon={<PlusSquareOutlined />}
                        onClick={() => manageCount('addition')}
                        // size={size}
                      />
                    </Col>
                  </Row>
                )
              );
            }}
          </Form.Item>
          <Form.List name={['product', 'payload']}>
            {(fields) => {
              return fields.map((field) => (
                <React.Fragment key={field.key}>
                  <Form.Item name={[field.name, 'name']} noStyle>
                    <Div />
                  </Form.Item>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prev, next) =>
                      prev.product?.payload[field.key]?.validityDateStart !==
                      next.product?.payload[field.key]?.validityDateStart
                    }
                  >
                    {({ getFieldValue }) => {
                      return (
                        getFieldValue([
                          'product',
                          'payload',
                          field.key,
                          'validityStartType',
                        ]) === 'FUTURE' && (
                          <Form.Item
                            noStyle
                            name={[field.name, 'startDate']}
                            rules={[
                              {
                                required: true,
                                message: t('errors.selectDate'),
                              },
                            ]}
                          >
                            <DatePickerLocale
                              showTime
                              shouldNotBeDisabled={false}
                              format="DD.MM.YYYY HH:mm"
                              style={{ width: '100%' }}
                            />
                          </Form.Item>
                        )
                      );
                    }}
                  </Form.Item>

                  <Form.List name={[field.name, 'restrictions']}>
                    {(fields2) =>
                      fields2.map((field2) => (
                        <Restrictions
                          key={field2.key}
                          form={form}
                          line={line}
                          whereIsRestriction={whereIsRestriction}
                          name={[field.name, 'restrictions', field2.name]}
                        />
                      ))
                    }
                  </Form.List>
                </React.Fragment>
              ));
            }}
          </Form.List>
        </Form>
      </Modal>
    </>
  );
};

export default Products;
