import { search } from '../helpers/api';

export const SERVICE_URI = '/portal';
export const BASE_URI = '/api/v1/accounts/black-list-reasons';

export const getBlackReasons = async () => {
  return search(`${SERVICE_URI}${BASE_URI}`, {
    criterias: {},
  });
};
