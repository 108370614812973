import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import {
  Badge,
  Button,
  Card,
  Col,
  Descriptions,
  Divider,
  Form,
  message,
  Modal,
  notification,
  Row,
  Skeleton,
  Tooltip,
} from 'antd';

import { PlusOutlined, ShoppingCartOutlined } from '@ant-design/icons';

import dayjs from 'dayjs';
import i18n from 'i18next';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import CartContext from '../CartContextWrapper';
import {
  getOfferTypes,
  getProductsByOfferTypeReq,
  getTitlesByAccountId,
} from '../../services/accounts';
import {
  addProduct,
  rechargeProduct,
  verifyProduct,
} from '../../services/orders';
import { getProductById } from '../../services/products';
import DatePickerLocale from '../DatePickerLocale';
import Restrictions from '../Restrictions';

const TransportTitles = ({
  id,
  cardBlocked,
  isDescendent,
  canAddTitles,
  fromCustomerType,
}) => {
  const { t } = useTranslation();
  const { language } = i18n;
  const { orders, refreshCart } = useContext(CartContext);

  const history = useHistory();
  const [currentProduct, setCurrentProduct] = useState();
  const [productForm] = Form.useForm();

  const [titles, setTitles] = useState(undefined);
  const [showTitles, setShowTitles] = useState(undefined);
  const [currentOffers, setCurrentOffers] = useState([]);
  const [line, setLine] = useState(null);
  const [whereIsRestriction, setWhereIsRestriction] = useState([]);
  const renderTitle = (title) => {
    if (title.linesName && !title.lineGroupsName) {
      return title.linesName;
    }
    if (title.lineGroupsName && !title.linesName) {
      return title.lineGroupsName;
    }
    if (title.lineGroupsName && title.linesName) {
      return `${title.lineGroupsName}, ${title.linesName}`;
    }
    return null;
  };
  useEffect(() => {
    getTitlesByAccountId(id).then((result) => {
      setTitles(result || []);
      setShowTitles(result.map(renderTitle));
    });
    getOfferTypes().then((result) => {
      const subscriptionOffers = result.find(
        (offer) => offer.code === 'ABONAMENT',
      );
      getProductsByOfferTypeReq(id, subscriptionOffers).then((res) => {
        const updatedProducts = (res || []).map((product) => {
          return {
            ...product,
            payload: product.payload.map((item) => ({
              ...item,
              restrictions: product.productRestrictions || [],
            })),
          };
        });
        setCurrentOffers(updatedProducts);
      });
    });
  }, [id]);

  dayjs.extend(customParseFormat);

  const handleRechargeProduct = useCallback(
    (value) => {
      const currentSelectedLine = productForm
        .getFieldValue(['product', 'payload'])
        .map((item) => {
          const results = [];
          const foundItem = item.restrictions.find(
            (el) => el.restriction === 'LINE_GROUPS',
          );
          results.push(foundItem);
          return results;
        })
        .flat();
      const currentTitle = titles.find(
        (el) => el.productId === currentProduct.id,
      );
      const now = new Date();
      const isDateAOutsideInterval =
        currentTitle?.endDateA === null ||
        (currentTitle?.endDateA != null &&
          new Date(currentTitle?.endDateA) < now);
      const isDateBOutsideInterval =
        currentTitle?.endDateB === null ||
        (currentTitle?.endDateB != null &&
          new Date(currentTitle?.endDateB) < now);
      currentSelectedLine.forEach((restr) => {
        if (
          currentTitle &&
          isDateAOutsideInterval === false &&
          isDateBOutsideInterval
        ) {
          const productToRecharge = value;
          delete productToRecharge.product;
          const req = {
            ...productToRecharge,
            count: 1,
            restriction: restr,
            localizedName: currentProduct.localizedName?.[language],
          };
          rechargeProduct(id, req)
            .then(() => {
              refreshCart();
              setCurrentProduct();
              productForm.resetFields();
              message.success({
                content: t('errors.productAddedToCart'),
                key: 'pos',
                duration: 1,
                className: 'card-message',
              });
            })
            .catch((err) => {
              console.error(err);
              notification.error({
                message: t('errors.productNotAddedToCart'),
                description: err.inner._
                  ? t(err.inner._)
                  : t('errors.productNotAddedToCart'),
                duration: 5,
                className: 'card-message',
                style: {
                  width: 1000,
                  marginRight: 200,
                },
              });
            });
        } else {
          const productBody = {
            id: currentProduct.id,
            category: currentProduct.category,
            payload: productForm.getFieldValue(['product', 'payload']),
          };
          addProduct(id, productBody)
            .then(() => {
              refreshCart();
              setCurrentProduct();
              message.success({
                content: t('errors.productAddedToCart'),
                key: 'pos',
                duration: 1,
                className: 'card-message',
              });
            })
            .catch((err) => {
              console.error(err);
              message.error({
                content: err?.inner?._
                  ? t(err?.inner?._)
                  : t('errors.productNotAddedToCart'),
                key: 'pos',
                duration: 1,
                className: 'card-message',
              });
            });
        }
      });
    },
    [currentProduct, productForm, titles, language, id, refreshCart, t],
  );

  const handleExtendProduct = useCallback(
    (product) => {
      const req = {
        id: product.id,
        count: product.productType === 'PURSE' ? 100 : 1,
        localizedName: product.productName,
      };

      rechargeProduct(id, req)
        .then(() => {
          refreshCart();
          message.success({
            content: t('errors.productAddedToCart'),
            key: 'pos',
            duration: 1,
            className: 'card-message',
          });
        })
        .catch((err) => {
          console.error(err);
          message.error({
            content: err.inner._
              ? t(err.inner._)
              : t('errors.productNotAddedToCart'),
            key: 'pos',
            duration: 1,
            className: 'card-message',
          });
        });
    },
    [id, refreshCart, t],
  );

  const handleChangeProduct = useCallback(
    (product) => {
      const currentProductToAdd =
        orders && orders.length > 0
          ? orders[0].products.find((p) => p.id === product.productId)
          : undefined;

      verifyProduct(
        product.productId,
        currentProductToAdd ? currentProductToAdd.qty : 0,
      )
        .then(() => {
          getProductById(product.productId)
            .then((value) => {
              if (value.validityStartType === 'FUTURE') {
                const selectedOffer = currentOffers.find(
                  (el) => el.id === value.id,
                );
                setCurrentProduct(value);
                productForm.setFieldsValue({ product: selectedOffer });
                const hasRestriction = selectedOffer.productRestrictions.some(
                  (item) =>
                    item.restriction === 'LINE_GROUPS' &&
                    (item.value1 === 1 ||
                      item.value3 === 1 ||
                      item.value5 === 1 ||
                      item.value7 === 1),
                );
                if (hasRestriction) {
                  const whereIsRestrictionData =
                    selectedOffer.productRestrictions
                      .map((item) => {
                        if (
                          item.restriction === 'LINE_GROUPS' &&
                          (item.value1 === 1 ||
                            item.value3 === 1 ||
                            item.value5 === 1 ||
                            item.value7 === 1)
                        ) {
                          const results = [];
                          if (item.value1 === 1) {
                            results.push('value1');
                          }
                          if (item.value3 === 1) {
                            results.push('value3');
                          }
                          if (item.value5 === 1) {
                            results.push('value5');
                          }
                          if (item.value7 === 1) {
                            results.push('value7');
                          }
                          return results;
                        }
                        return [];
                      })
                      .filter((list) => list.length > 0);

                  const ownedTitle = product !== undefined ? product : null;
                  const now = new Date();
                  const isDateAOutsideInterval =
                    ownedTitle?.endDateA === null ||
                    (ownedTitle?.endDateA != null &&
                      new Date(ownedTitle?.endDateA) < now);
                  const isDateBOutsideInterval =
                    ownedTitle?.endDateB === null ||
                    (ownedTitle?.endDateB != null &&
                      new Date(ownedTitle?.endDateB) < now);
                  const canAddTitleOnEveryLine =
                    ownedTitle === null ||
                    (isDateBOutsideInterval === true &&
                      isDateAOutsideInterval === true);
                  if (
                    selectedOffer?.category === 'PASS' &&
                    hasRestriction &&
                    canAddTitleOnEveryLine === false
                  ) {
                    setLine(ownedTitle?.lines);
                    setWhereIsRestriction(whereIsRestrictionData);
                  }
                }
                productForm.setFieldsValue({
                  id: product.id,
                  count: value.category === 'PASS' ? null : 1,
                  localizedName: product?.productName,
                });
              } else {
                handleExtendProduct(product);
              }
            })
            .catch((err) => {
              console.error(err);
              message.error({
                content: err.inner._
                  ? t(err.inner._)
                  : t('errors.productNotAddedToCart'),
                key: 'pos',
                duration: 5,
                className: 'card-message',
              });
            });
        })
        .catch((err) => {
          notification.error({
            message: t('errors.productNotAddedToCart'),
            description: err.inner._
              ? t(err.inner._)
              : t('errors.productNotAddedToCart'),
            duration: 5,
            className: 'card-message',
            style: {
              width: 1000,
              marginRight: 200,
            },
          });
        });
    },
    [orders, currentOffers, productForm, handleExtendProduct, t],
  );

  return (
    <>
      <Divider style={{ fontWeight: 500, fontSize: '1.5rem' }}>
        {t('entity.account.transportTitle._plural')}
      </Divider>

      <Skeleton loading={titles === undefined} active>
        <Row gutter={[100, 100]}>
          {(titles || []).map((title, idx) => {
            return (
              <Col span={6} key={title.id}>
                <Badge.Ribbon text={title.productType}>
                  <Card
                    style={{
                      boxShadow: '5px 5px 15px 5px rgba(0,0,0,0.24)',
                      WebkitBoxShadow: '5px 5px 15px 5px rgba(0,0,0,0.24)',
                    }}
                    title={
                      <Tooltip
                        title={
                          title?.localizedName?.[language] ===
                            'Do not defined' ||
                          title?.localizedName?.[language] === null
                            ? title?.localizedName?.ro
                            : title?.localizedName?.[language]
                        }
                      >
                        <span>
                          {title?.localizedName?.[language] ===
                            'Do not defined' ||
                          title?.localizedName?.[language] === null
                            ? title?.localizedName?.ro
                            : title?.localizedName?.[language]}{' '}
                          <Badge count={title.tripQuantity} />{' '}
                        </span>
                      </Tooltip>
                    }
                    bordered
                    actions={
                      title.productType === 'PURSE'
                        ? []
                        : [
                            // title.active && (
                            //   <Tooltip title={t('actions.disable')} key="disable">
                            //     <PauseCircleOutlined />
                            //   </Tooltip>
                            // ),
                            // !title.active && (
                            //   <Tooltip title={t('actions.enable')} key="enable">
                            //     <PlayCircleOutlined />
                            //   </Tooltip>
                            // ),
                            <Tooltip title={t('actions.extend')} key="extend">
                              {isDescendent === undefined && !isDescendent ? (
                                <ShoppingCartOutlined
                                  onClick={() =>
                                    !cardBlocked
                                      ? handleChangeProduct(title)
                                      : notification.error({
                                          message: t(`errors.cardBlocked`),
                                        })
                                  }
                                />
                              ) : (
                                <ShoppingCartOutlined
                                  style={{
                                    pointerEvents: 'none',
                                    color: 'rgba(0, 0, 0, 0.25)',
                                  }}
                                />
                              )}
                            </Tooltip>,
                          ].filter((v) => !!v)
                    }
                  >
                    <Descriptions column={1} style={{ height: '96px' }}>
                      {title.productType === 'PURSE' ? (
                        <Descriptions.Item
                          label={t(`entity.account.transportTitle.purseValue`)}
                        >
                          {`${parseFloat(title.purseValue / 100).toFixed(
                            2,
                          )} RON` || ''}
                        </Descriptions.Item>
                      ) : (
                        <Descriptions.Item
                          label={t(`entity.account.transportTitle.validity`)}
                        >
                          {(title.validPeriod || '').split(',').map((str) => (
                            <React.Fragment key={str}>
                              {str}
                              <br />
                            </React.Fragment>
                          ))}
                        </Descriptions.Item>
                      )}
                      {showTitles && showTitles[idx] ? (
                        <Descriptions.Item
                          label={t(`entity.account.transportTitle.lines`)}
                        >
                          {showTitles[idx]}
                        </Descriptions.Item>
                      ) : null}
                    </Descriptions>
                  </Card>
                </Badge.Ribbon>
              </Col>
            );
          })}
          {((isDescendent === undefined && !isDescendent) || canAddTitles) && (
            <Col
              span={6}
              key="purchase"
              style={{
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
              }}
            >
              <div style={{ height: '96px' }}>
                <Tooltip title={t('actions.buy')} key="buy">
                  <Button
                    shape="round"
                    disabled={cardBlocked}
                    icon={<PlusOutlined />}
                    size="large"
                    onClick={() =>
                      history.push({
                        pathname: `/accounts/${id}/products`,
                        state: {
                          isDescendent,
                          fromCustomerType,
                        },
                      })
                    }
                  >
                    {t(`actions.buyTitle`)}
                  </Button>
                </Tooltip>
              </div>
            </Col>
          )}
        </Row>
      </Skeleton>
      <Modal
        title={t(`entity.orders.configuration`)}
        visible={currentProduct}
        onOk={() => {
          productForm
            .validateFields()
            .then(handleRechargeProduct)
            .catch((info) => {
              console.error('Validate Failed:', info);
            });
        }}
        onCancel={() => {
          setCurrentProduct();
          productForm.resetFields();
        }}
      >
        <Form form={productForm} layout="vertical">
          <Form.Item hidden name={['id']} />
          <Form.Item hidden name={['count']} />
          {currentProduct && currentProduct.validityStartType === 'FUTURE' && (
            <Form.Item noStyle name={['startDate']}>
              <DatePickerLocale
                showTime
                format="DD.MM.YYYY HH:mm"
                shouldNotBeDisabled={false}
                style={{ width: '100%' }}
              />
            </Form.Item>
          )}

          <Form.List name={['product', 'payload']}>
            {(fields) => {
              return fields.map((field) => (
                <React.Fragment key={field.key}>
                  <Form.List name={[field.name, 'restrictions']}>
                    {(fields2) =>
                      fields2.map((field2) => (
                        <Restrictions
                          key={field2.key}
                          form={productForm}
                          line={line}
                          whereIsRestriction={whereIsRestriction}
                          name={[field.name, 'restrictions', field2.name]}
                        />
                      ))
                    }
                  </Form.List>
                </React.Fragment>
              ));
            }}
          </Form.List>
        </Form>
      </Modal>
    </>
  );
};

export default TransportTitles;
